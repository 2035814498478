import { AfterViewInit, Component, ElementRef, HostBinding, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, DocumentInterruptSource, StorageInterruptSource } from '@ng-idle/core';
import * as _ from 'lodash';
import { CookieService } from 'ngx-cookie-service';
import { combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { IdleTimeoutService } from './core/services/bs-api/session-idle-timeout/bs-idle-timeout.service';
import { BsSpinnerService } from './core/services/bs-spinner/bs-spinner.service';
import { LoggerService } from './core/services/logger/logger.service';
import { RoutingStateService } from './core/services/routing/routing-state.service';
import { PageTitleService } from './core/services/title/page-title.service';
import { CodeFeaturesService } from './core/services/user/code-features.service';
import { Constants } from './core/utilities/constants';
import { StringUtilities } from './core/utilities/string-utilities';
import { UtilityService } from './core/utilities/utility.service';
import { CurrentUserStore } from './user/store/current-user/current-user.store';
import { IRadSpinner } from './core/model/rad-components/rad-components';
import environment from '@environments/environment';
import { BetaPreviewService } from './core/services/beta-preview.service';
import { TrashItemMessageTypes } from './core/store/trash-items/trash-items.store';
import { MessageService } from './core/services/message/message.service';

// GogleTagManager, declaring like this to avoid adding an unecessary types package
declare let gtag: Function;

// Using body as a selector instead of <app-root> to reduce element tags for CSS simplicity of layout
@Component({
  // tslint:disable-next-line component-selector
  selector: 'body',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostBinding('class') public bodyClass: string = '';

  public title = 'bssh-ui';
  public isPageForeground = false;
  public isDashboard = false;
  public showSpotlight = true;
  private _subs: SubSink = new SubSink();
  public layoutClasses: any;
  public navBarTransparent = false;

  @ViewChild('layout', { static: false })
  public layout: ElementRef<any>;

  @ViewChild('spinnerLoader', { static: false }) public spinnerLoader: IRadSpinner;

  private subs = new SubSink();

  public currentYearCopyright = new Date().getFullYear().toString();

  constructor(
    private _elementRef: ElementRef,
    private idleTimeoutService: IdleTimeoutService,
    private cookieService: CookieService,
    private utilityService: UtilityService,
    private userStore: CurrentUserStore,
    private _router: Router,
    private routingStateService: RoutingStateService,
    private logger: LoggerService,
    private pageTitleService: PageTitleService,
    private codeFeaturesService: CodeFeaturesService,
    private bsSpinnerService: BsSpinnerService,
    private betaPreviewService: BetaPreviewService,
    private messageService: MessageService
  ) {
  }

  ngOnInit() {
    this.bodyClass = this.setLayoutClasses();
  }

  ngAfterViewInit(): void {
    this.bodyClass = this.setLayoutClasses();

    const foregroundPageList = [
      '/runs',
      '/runs/active',
      '/runs/planned',
    ];

    this.routingStateService.loadRouting();
    this.pageTitleService.initialize();

    // Determines if the page layout should be grey/background (typical), or white/foreground (atypical)
    // Filtering for NavigationEnd before evaluating and setting class on body tag
    // Purple/grey === Dashbaord, Pink/Grey === Most Pages, Pink/white (foreground) === Master lists
    this._subs.sink = this._router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((value: RouterEvent) => {
      if (value && value.url) {
        const inPageList = _.find(foregroundPageList, (route) => value.url === route);
        this.isPageForeground = inPageList !== undefined;
        this.isDashboard = value.url === '/dashboard';
        this.navBarTransparent = this.shouldNavBarBeTransperant(value.url);
        this.bodyClass = this.setLayoutClasses();
      }
    });

    // when page navigation completes + user is loaded, log to google tag mgr
    this._subs.sink = combineLatest(
      this._router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ),
      this.userStore.stateChanged.pipe(
        filter(state => state != null && state.currentUserId != null && state.currentUserStateError == null)
      )
    ).subscribe({
      next: ([value]) => {
        // use urlAfterRedirects if avail to correct old paths or empty paths like the dashboard
        const navigationEvent = value as NavigationEnd;
        if (navigationEvent && navigationEvent.urlAfterRedirects) {
          this.logPageNavstoGoogleTags(navigationEvent.urlAfterRedirects);
        } else if (navigationEvent && navigationEvent.url) {
          this.logPageNavstoGoogleTags(navigationEvent.url);
        } else {
          return;
        }
      }
    });

    // Update trash items count when user context shifted (eg switch to workgroup)
    this.subs.sink = this.userStore.contextChanged$.subscribe(() => {
      this.messageService.sendMessage({ type: TrashItemMessageTypes.UpdateTrashItemsCount });
    });

    this.subs.sink = this.bsSpinnerService.loading.subscribe((loading) => {
      if(loading) {
        this.spinnerLoader.nativeElement.start();
        this._elementRef.nativeElement.ownerDocument.body.style.overflow = 'hidden';
      } else {
        this.spinnerLoader.nativeElement.complete();
        this._elementRef.nativeElement.ownerDocument.body.style.overflow = null;
      }
    });

    this.subs.sink = this.userStore.loading$.pipe(
      filter(state => state != null)
    ).subscribe({
      next: state => {
        if (state) {
          this.bsSpinnerService.loadingSubject.next(true);
        } else {
          this.bsSpinnerService.loadingSubject.next(false);
        }
      }
    });

    this.subs.sink = this.userStore.stateChanged.pipe(
      filter(state => state != null && state.currentUserId != null && state.currentUserStateError == null)
    ).subscribe({
      next: (state) => {
        this.initIdleTimeout();
        
        const isCodeFeatureEnabled = this.codeFeaturesService.isUserInterfaceBetaPreviewAccessible;
        this.betaPreviewService.enableBetaPreviewIfUserHasAccess(isCodeFeatureEnabled);
      }
    });

    // Rad spinner has default opacity of 0.7 which is a bit too bright
    const radSpinner = this._elementRef.nativeElement.querySelector('rad-spinner');
    const style = document.createElement('style');
    style.textContent = `
    .rad-spinner__overlay {
      background-color: rgba(255, 255, 255, 0.5);
    }
    `;

    radSpinner.shadowRoot.appendChild(style);
  }

  initIdleTimeout() {
    let idleTimeout = Constants.PlatformSessionTrack.defaultIdleTimeOutInSeconds;
    let pingInterval = Constants.PlatformSessionTrack.defaultKeepAliveIntervalInSeconds;

    if (this.cookieService.get(Constants.PlatformSessionTrack.idleTimeoutInSeconds)) {
      // tslint:disable-next-line:max-line-length
      idleTimeout = parseInt(this.cookieService.get(Constants.PlatformSessionTrack.idleTimeoutInSeconds), 10) || 0; // 1800 secs = 30 mints
    }

    if (this.cookieService.get(Constants.PlatformSessionTrack.idleTimeoutIntervalCheckInSeconds)) {
      // tslint:disable-next-line:max-line-length
      pingInterval = parseInt(this.cookieService.get(Constants.PlatformSessionTrack.idleTimeoutIntervalCheckInSeconds), 10) || 0; // 840 secs - 14 mints
    }

    // tslint:disable-next-line:max-line-length
    this.idleTimeoutService.setInterrupts(StringUtilities.isBlank(Constants.SessionIdleSettings.sessionIdleInterruptSources) ? DEFAULT_INTERRUPTSOURCES :
      [new DocumentInterruptSource(Constants.SessionIdleSettings.sessionIdleInterruptSources),
      new StorageInterruptSource()]);

    // set idle timeout of 1800-60+3 seconds(29.05 minutes)
    this.idleTimeoutService.setIdle(idleTimeout - Constants.PlatformSessionTrack.defaultIdleTimeOutWaitTimeInSeconds + 3);
    // set idle timeout period of 60 seconds(1 minute)
    this.idleTimeoutService.setTimeout(Constants.PlatformSessionTrack.defaultIdleTimeOutWaitTimeInSeconds);
    this.idleTimeoutService.setKeepaliveInterval(pingInterval);
    this.idleTimeoutService.setActiveTime(this.utilityService.getCurrentTimeInSec());
    this.idleTimeoutService.startIdleWatcher(Constants.SessionIdleSettings.enabled);
  }

  public setLayoutClasses(): any {
    let className = '';
    if (this.isDashboard) {
      className = 'layout--dashboard';
    } else if (this.isPageForeground) {
      className = 'layout--foreground';
    }
    return className;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private shouldNavBarBeTransperant(routeUrl: string): boolean {
    // The nav bar will be transparent on Dashboard and the http error pages, to match up the background image.
    return routeUrl === '/dashboard' || routeUrl === `/${Constants.HTTP_ERROR_ROUTE}`;
  }

  private logPageNavstoGoogleTags(url: string) {
    if (!this.codeFeaturesService.isGoogleTagMgrEnabled) {
      return;
    }

    gtag('config', environment.googleAnalyticsCode,
      {
        'page_path': url
      }
    );

  }
}
