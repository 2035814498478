<ngx-smart-modal #referenceFile
  [identifier]="modalType"
  [title]="title"
  [closable]="showCloseButton"
  [dismissible]="showCloseButton"
  [escapable]="showCloseButton"
  [closeButtonText]="closeButtonText"
  [confirmButtonText]="confirmButtonText"
  [disableConfirmButton]="disableConfirmButton"
  [showCloseButton]="showCloseButton"
>


  <form [formGroup]="referenceFileFormGroup" novalidate ng-submit="onCreateReferenceFileClick()" autocomplete="off">
    <div class="form-group row">
      <div class="col-3 col-lg-2">
        File name
      </div>
      <div class="col-6 col-lg-7">
        <input lib-input formControlName="fileName" type="text" maxLength="255">
      </div>
    </div>

    <div class="form-group row">
      <div class="col-3 col-lg-2">
        Description
      </div>
      <div class="col-6 col-lg-7">
        <textarea class="input__textarea" lib-input formControlName="description" type="text" placeholder="optional">
        </textarea>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-3 col-lg-2">
        File type
      </div>
      <div class="col-6 col-lg-7">
        <div class="my-3">
          <combobox formControlName="fileType"
                    #referenceFileTypeOptionsComboBox
                    label="Reference file type"
                    (change)="toggleOthers($event)"
                    [disabled]="true"
          >
            <ng-container>
              <combobox-option *ngFor="let option of referenceFileTypeOptions" [value]="option" [label]="option"></combobox-option>
            </ng-container>
          </combobox>
        </div>
        <div class="my-3" [hidden]="!othersSelected">
          <input lib-input formControlName="fileTypeOthers" type="text" maxLength="255" placeholder="Type">
        </div>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-3 col-lg-2">
        Related genomes
      </div>
      <div class="col-9 col-lg-10">
        <lib-radio-checkbox-list type="checkbox" #supportedGenomeIds [options]="genomesList"
                                 formControlName="supportedGenomeIds" valueProperty="id" labelProperty="text">
        </lib-radio-checkbox-list>
      </div>
    </div>
  </form>

  <section #wrapperLoader class="wrapper" [ngClass]="{active: isUploading}" *ngIf="isUploading"
           data-cy="progress-overlay-wrapper">
  </section>
  <lib-progress class="run-setup-spinner" id="file-upload-loader" #refFileLoadingSpinner [hidden]="!isUploading"
    [loader]="false" [spinner]="true" spinnerPosition="center" [fixed]="true" data-cy="progress-overlay-spinner">
  </lib-progress>
</ngx-smart-modal>
