import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { IApiFile } from '../model/file-preview.interface';
import { SubSink } from 'subsink';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { NgxSmartModalService, IRequestedFile, FilePreviewComponentOutput } from '@bssh/comp-lib';
import { IFilePreviewModalInput, IFilePreviewModalOutput } from '../model/action-modal';
import { isNullOrUndefined } from 'util';
import { ModalTexts } from '../modal-texts';

@Component({
  selector: 'app-file-preview-modal',
  templateUrl: './file-preview-modal.component.html',
  styleUrls: ['./file-preview-modal.component.scss']
})
export class FilePreviewModalComponent extends BaseModalComponent implements OnInit {

  modalType = 'FilePreviewModal';
  modalTitle: string = null; // TOOD: get Modal Title
  confirmButtonText = ModalTexts.FILE_PREVIEW.CONFIRM_BUTTON_TEXT;

  public apiBaseUrl: string = null;
  public file: IRequestedFile = null;

  constructor(public ngxSmartModalService: NgxSmartModalService) {
    super(ngxSmartModalService);

  }

  ngOnInit() {
    super.ngOnInit();
    this.setUpInputSubscribers();
  }

  dataChanged($event) {
    const _data: FilePreviewComponentOutput = $event;

    // if valid downloadLink then show download button
    if (_data.downloadLink) {
      this.disableConfirmButton = false;
    } else {
      this.disableConfirmButton = true;
    }

    const filePreviewData: IFilePreviewModalOutput = {
      fileName: this.file.Name,
      downloadLink: _data.downloadLink,
    };
    this.modal.setData(filePreviewData, true);

  }

  private setUpInputSubscribers() {
    this.subs.sink = this.data.subscribe((input: IFilePreviewModalInput) => {
      if (isNullOrUndefined(input)) {
        return;
      }

      this.apiBaseUrl = input.apiBaseUrl;
      this.file = this.toIRequestedFile(input.requestedFile);
      this.modalTitle = this.file.Name;
    });
  }

  toIRequestedFile(file: IApiFile): IRequestedFile {
    if (!file) {
      return null;
    }
    const requestedFile: IRequestedFile = {
      ...file,
      extension: this.getFileExtension(file.Name)
    };

    return requestedFile;
  }

  private getFileExtension(name: string): string {
    if (!name) {
      return null;
    }

    const values: string[] = name.split(/\.(?=[^\.]+$)/);
    return values[values.length - 1];
  }

}
