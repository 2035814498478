import { Injectable } from '@angular/core';
import { BsApiEndPoints, IBsshApiEndpoint } from '../bs-api/endpoints';
import { BaseService } from '../base.service';
import { BasespaceService } from '@bssh/ng-sdk';
import { Constants } from '@app/core/utilities/constants';
import { HttpRequest } from '@angular/common/http';
import { ConsoleLogger } from '@app/core/utilities/consolelogger';



export interface IErrorRedirectService {
  getExcludedApiPathsFor404Redirect(): IBsshApiEndpoint[];
  getExcludedApiPathsFor403Redirect(): IBsshApiEndpoint[];
}


@Injectable({
  providedIn: 'root'
})
export class ErrorRedirectService extends BaseService implements IErrorRedirectService {


  // Maintain a list of blacklist apis, for which we do not want the 403 redirect.
   private byPassApisFor403: IBsshApiEndpoint[] = [
    { method: 'POST', path: BasespaceService.PostV2BiosamplesBulkupdatePath, code: Constants.insufficientResourcePermissions},
    { method: 'POST', path: BasespaceService.PostV2AppsessionsIdPath, code: Constants.insufficientResourcePermissions, pathRegex: new RegExp('\/appsessions\/+')},
    { method: 'POST', path: BasespaceService.PostV2DatasetsIdCopyPath, code: Constants.insufficientResourcePermissions, pathRegex: new RegExp('\/datasets\/ds[.].+\/copy')},
    { method: 'POST', path: BasespaceService.PostV2DatasetsIdPath, code: Constants.insufficientResourcePermissions, pathRegex: new RegExp('\/datasets\/ds[.].+')},
    { method: 'GET', path: BsApiEndPoints.V2_DATASETS, code: Constants.insufficientResourcePermissions, pathRegex: new RegExp('\/datasets\/ds[^\/]+\/properties.*')},
    { method: 'DELETE', path: BsApiEndPoints.V2_DATASETS, code: Constants.basespaceDMNotResourceOwnerErrorCode},
    { method: 'DELETE', path: BsApiEndPoints.V2_DATASETS, code: Constants.basespaceDMTrashUnshareProjectChildNotAllowed},
    { method: 'DELETE', path: BsApiEndPoints.V1PRE3_APPSESSIONS, code: Constants.basespaceDMNotResourceOwnerErrorCode },
    { method: 'DELETE', path: BsApiEndPoints.V1PRE3_APPSESSIONS, code: Constants.basespaceDMTrashUnshareProjectChildNotAllowed},
    { method: 'POST', path: BsApiEndPoints.BULK_DELETE_URL, code: Constants.basespaceDMNotResourceOwnerErrorCode},
    { method: 'POST', path: BsApiEndPoints.BULK_DELETE_URL, code: Constants.basespaceDMTrashUnshareProjectChildNotAllowed}
  ];

  // Maintain a list of blacklist apis, for which we do not want the 404 redirect.
  private byPassApisFor404: IBsshApiEndpoint[] = [
    { method: 'GET', path: BsApiEndPoints.V2_USER_NOTIFICATIONS_API_PATH },
    { method: 'GET', path: BsApiEndPoints.V1_APPSESSION_APPRESULT_PATH, code: 'BASESPACE.COMMON.NOT_FOUND',  pathRegex: new RegExp('\/appsessions\/[0-9]+\/Properties\/Output[.]Datasets.+') },
    { method: 'GET', path: BsApiEndPoints.V2_APPSESSION_PROPERTIES_LOGS_TAIL, code: 'BASESPACE.COMMON.NOT_FOUND', pathRegex: new RegExp('\/appsessions\/[0-9]+\/Properties\/Logs[.]Tail', 'i') },
    { method: 'GET', path: BsApiEndPoints.V2_APPSESSION_PROPERTIES, code: 'BASESPACE.COMMON.NOT_FOUND', pathRegex: new RegExp('\/appsessions\/[0-9]+\/Properties.*', 'i') },
    { method: 'GET', path: BsApiEndPoints.DATASET_TYPES_PATH, code: 'BASESPACE.COMMON.NOT_FOUND', pathRegex: new RegExp('\/datasettypes\/.*$', 'i')},
    { method: 'GET', path: BsApiEndPoints.v1GetInvite },
  ];

  // Right now, this service simply returns a pre-configured static list of api paths
  // But can be enhanced to make decisions based on route/page context/environment variables etc.
  constructor() {
    super();
  }

  /**
   * Returns a list of api paths from which 404 is expected, but we need not redirect to 404 page,
   * because they provide non-critical background information, and is handled in the respective component.
   */
  getExcludedApiPathsFor404Redirect() {
    return this.byPassApisFor404;
  }

  /**
   * Returns a list of api paths from which 403 is expected, but we need not redirect to 403 page
   */
  getExcludedApiPathsFor403Redirect(): IBsshApiEndpoint[] {
    return this.byPassApisFor403;
  }

  /**
   * Returns a boolean whether to prevent redirection to a specific page or not
   * @param req HttpRequest
   * @param responseStatusCode
   * @param errorCode
   */
  shouldPreventErrorRedirect(req: HttpRequest<any>, responseStatusCode: number, errorCode: string): boolean {
    const apisExcludedFor403Redirect = this.getExcludedApiPathsFor403Redirect();

    switch (responseStatusCode) {
      case 403:
        const isApiExcluded: boolean = apisExcludedFor403Redirect.
          some(api => req.method === api.method && errorCode.includes(api.code) &&
            (req.urlWithParams.includes(api.path) || (api.pathRegex != null ? api.pathRegex.test(req.urlWithParams) : false)));
        return isApiExcluded;

      default:
        return false;
    }
  }
}