<section class="layout" *appAuthProtected>
  <section class="layout__header" id="header">
    <app-bs-univ-nav-bar *appAuthProtected class="layout__header__global-nav" [transparent]="navBarTransparent"></app-bs-univ-nav-bar>
  </section>

  <section class="layout__page">
    <router-outlet></router-outlet>
  </section>

  <div class="layout__footer-push"></div>
</section>

<div class="layout__copyright-footer">©2022-{{currentYearCopyright}} Illumina, Inc.</div> 

<rad-spinner
  #spinnerLoader
  class="spinner-loader"
  data-cy="progress-overlay-spinner"
></rad-spinner>
