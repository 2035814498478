import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ObservableStore } from '@codewithdan/observable-store';
import { ReduxDevToolsExtension } from '@codewithdan/observable-store-extensions';
import {LicenseManager} from "@ag-grid-enterprise/core";

import { AppModule } from './app/app.module';
import environment from './environments/environment';

// ag-grid
LicenseManager.setLicenseKey(`CompanyName=illumina, Inc.,LicensedApplication=BaseSpace Sequence Hub,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=25,LicensedProductionInstancesCount=10,AssetReference=AG-009109,ExpiryDate=27_July_2021_[v2]_MTYyNzM0MDQwMDAwMA==fbefe408f25c6924c35c45b188bed039`);

if (environment.production) {
  enableProdMode();
}
// enable state history tracking for local only.
ObservableStore.globalSettings = {
  isProduction: environment.production,
  trackStateHistory: !environment.production,
  logStateChanges: false
};

if (!ObservableStore.globalSettings.isProduction) {
  ObservableStore.addExtension(new ReduxDevToolsExtension());
}
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
