import { Injectable, Inject } from '@angular/core';
import _ from 'lodash';
import { DOCUMENT } from '@angular/common';
import environment from '@environments/environment';
import { Constants } from '@app/core/utilities/constants';
import { StringUtilities } from '@app/core/utilities/string-utilities';

/**
 * A Utility service which contains common HTTP based utilities & tasks
 */
@Injectable({
  providedIn: 'root'
})
export class HttpUtilityService {

  private httpsPrefix = 'https';

  constructor(@Inject(DOCUMENT) private document: Document) { }

  /**
   * Redirects to a specified Url
   * @param urlToRedirect The Url to redirect
   */
  public redirectToUrl(urlToRedirect: string) {
    const url = _.startsWith(urlToRedirect, this.httpsPrefix) ? urlToRedirect : `${this.httpsPrefix}://${urlToRedirect}`;
    this.document.location.href = url;
  }

  /**
   * Redirects to a specified Domain
   * @param domainToRedirect The Domain to redirect
   * @param pathAndQuery The path and Query to redirect
   */
  public redirectToDomain(domainToRedirect: string, pathAndQuery: string) {
    const publicDomainUrl = environment.siteUrl;
    let enterpriseUrl = publicDomainUrl.replace(`${this.httpsPrefix}://`, `${this.httpsPrefix}://${domainToRedirect}.`);
    enterpriseUrl = enterpriseUrl + `/${pathAndQuery}`;
    this.redirectToUrl(enterpriseUrl);
  }

  /**
   * Gets platform domain name
   */
   public getPlatformDomainName(baseDomain: string) {
    const publicDomainUrl = environment.siteUrl;
    let platformDomain = publicDomainUrl.replace(`${this.httpsPrefix}://`, `${baseDomain}`);
    platformDomain =  platformDomain.substring(platformDomain.indexOf(environment.psTokenDomain));
    return platformDomain;
  }

  /**
   * Gets platform home url (used in accounts change password/edit profile hrefs)
   * PlatformUrl and DomainName change based on the environments
   * PlatformDomainName is accounts for public domain, enterprise for enterprise domain etc..
   * URL: https://login.testing.domain.illumina.com/platform-services-manager/ for test environments
   * URL: https://login.illumina.com/platform-services-manager/ for prod etc..
   */
  public getPlatformHomeUrl() {
    const platformDomain = environment.platformDomain;
    const platformUrl = environment.platformUrl.replace(`${this.httpsPrefix}://`, `${this.httpsPrefix}://${platformDomain}.`);
    // Stable PlatformHome Url:: https://{domainName}.{platformUrl}/platform-home/#
    const platformHomeUrl = platformUrl.replace('platform-services-manager', 'platform-home/#');
    return platformHomeUrl;
  }

  /**
   * Gets workgroup admin console (wac) url.
   * Returns wac with enterprise domain when user is on enterprise domain.
   */
  public getWorkgroupAdminConsoleUrl(isPublicDomainUser: boolean) {
    let workgroupAdminConsoleUrl = environment.workgroupAdminUrl;
    // https://git.illumina.com/BaseSpace/basespace/blob/develop/src/Cloud.Web/Controllers/AbstractNoAuthController.cs#L445
    if (!isPublicDomainUser) {
      workgroupAdminConsoleUrl = workgroupAdminConsoleUrl.replace(environment.platformDomain, this.getDomainFromCurrentRequestUrl());
    }
    return workgroupAdminConsoleUrl;
  }

  /**
   * Gets the current request url
   */
  public getCurrentRequestUrl() {
    return this.document.location.href;
  }

  /**
   * Gets the Base Url segment of the current request url
   * E.g: For Url: https://www.illumina.com/company/contact-us/, returns  https://www.illumina.com
   */
  public getBaseUrlFromCurrentRequestUrl() {
    return this.document.location.origin;
  }

  /**
   * Gets the Base Url segment of the current request url
   * E.g: For Url: https://www.illumina.com/company/contact-us/, returns  'company/contact-us/'
   */
  public getPathAndQueryFromCurrentRequestUrl() {
    return `${this.document.location.pathname}${this.document.location.search}`.substr(1);
  }


  /**
   * Gets the Base Url segment of the current request url
   * E.g: For Url: https://www.illumina.com/company/contact-us/, returns  '/company/contact-us/'
   */
  public getPathFromCurrentRequestUrl() {
    return `${this.document.location.pathname}`;
  }

  /**
   * Gets the Base Url segment of the current request url
   * E.g: For Url: https://mydomain.illumina.com/company/contact-us/, returns  'mydomain'
   */
  public getDomainFromCurrentRequestUrl(): string {
    return this.document.location.host.split('.')[0];
  }

  public getDomainFromUrl(url: string): string {
    return new URL(url).host.split('.')[0];
  }

  public getQueryParamsFromUrl(url: string): Object {
    const urlObject = new URL(url);
    const queryParams = {};
    urlObject.searchParams.forEach((value, key) => {
      queryParams[key] = value;
    });
    return queryParams;
  }

  public getRelativePathFromUrl(url: string): string {
    return new URL(url).pathname;
  }

  /**
   * Gets the base url for a given URL.
   * E.g: For Url: https://www.illumina.com/company/contact-us/, returns  https://www.illumina.com
   * @param url The Url
   */
  public getBaseUrl(url: string): string {
    const urlObj = new URL(url);
    return urlObj.origin;
  }

  public isLocalEnvironment(): boolean {
    return environment.environmentName === Constants.LocalEnvironmentName;
  }

  public getQueryParamValueFromUrl(url: string, paramName: string): string {
    const urlObj = new URL(url);
    const parsedPathname = urlObj.pathname.split('/');
    const propertyIndex = parsedPathname.findIndex((property) => property === paramName);
    if(propertyIndex < 0) {
      return null;
    } else {
      return parsedPathname[propertyIndex + 1];
    }
  }

  /**
   * Gets the domain from the decoded pstoken
   * @param psToken The Ps Token.
   */
  getDomainFromPsToken(psToken: string) {
    try {
      if (!StringUtilities.isBlank(psToken)) {
        const psTokenDecodedSegments = StringUtilities.fromBase64String(psToken).split(',');
        if (psTokenDecodedSegments != null && psTokenDecodedSegments.length > 1) {
          return psTokenDecodedSegments[0];
        }
      }
    } catch (error) {
      // Do nothing. Return null.
    }
    return null;
  }

}
