import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {
  GenomesService, ReferenceFile,
  ReferenceFilesService, UpdateReferenceFileRequest
} from '@stratus/gss-ng-sdk';
import {
  NgxSmartModalComponent,
  NgxSmartModalService,
  ProgressComponent,
  ToastrService
} from '@bssh/comp-lib';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseModalComponent } from '../../base-modal/base-modal.component';
import { CloudRunMapperService } from '@app/core/services/mapper/cloud-run-mapper.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { IReferenceFileModalInput } from '../../model/action-modal';
import { first, map } from 'rxjs/operators';
import { CustomOption } from '@app/run-planning/model/option';
import { LIST_GENOMES_PAGE_SIZE } from '@app/cloud-run-prep/constants';

const REFERENCE_FILE_DESCRIPTION_MAX_LENGTH = 8192;
const REFERENCE_FILE_TYPE_MAX_LENGTH = 255;

@Component({
  selector: 'app-reference-file-modal',
  templateUrl: './reference-file-modal.component.html',
  styleUrls: ['./reference-file-modal.component.scss']
})
export class ReferenceFileModalComponent extends BaseModalComponent implements OnInit, AfterViewInit {

  @Input() data = new BehaviorSubject<IReferenceFileModalInput>(undefined);

  modalType = 'referenceFile';
  title = 'Add Custom Reference File';
  closeButtonText = 'Cancel';
  confirmButtonText = 'Save';
  /**
   * Will remove the cancel button altogether to prevent user from exiting the modal when uploading
   * because no option to disableCancelButton (like the disableConfirmButton attribute)
   */
  showCloseButton = false;

  referenceFileFormGroup: FormGroup;

  @ViewChild('refFileLoadingSpinner', {static: false})
  refFileLoadingSpinner: ProgressComponent;
  isUploading = false;

  @ViewChild('fileInput', {static: false})
  fileInput: ElementRef;

  genomesList: CustomOption[];
  referenceFileTypeOptions: string[];
  othersSelected: boolean;
  private readonly FILE_TYPE_OTHER = 'Other';

  constructor(
    private mapper: CloudRunMapperService = null,
    public ngxSmartModalService: NgxSmartModalService,
    private toastr: ToastrService,
    private genomeService: GenomesService,
    private referenceFilesService: ReferenceFilesService,
  ) {
    super(ngxSmartModalService);
  }

  ngOnInit() {
    this.disableConfirmButton = true;
    this.showCloseButton = true;
    this.initializeFormGroup();
  }

  async ngAfterViewInit() {
    super.ngAfterViewInit();
    await this.initializeFormOptions();
    this.disableConfirmButton = false;
  }

  private initializeFormGroup() {
    this.referenceFileFormGroup = new FormGroup({
      fileName: new FormControl({ value: '', disabled: true }),
      description: new FormControl('', [Validators.maxLength(REFERENCE_FILE_DESCRIPTION_MAX_LENGTH)]),
      fileType: new FormControl('', [Validators.maxLength(REFERENCE_FILE_TYPE_MAX_LENGTH)]),
      fileTypeOthers: new FormControl('', [Validators.maxLength(REFERENCE_FILE_TYPE_MAX_LENGTH)]),
      supportedGenomeIds: new FormControl([]),
      dataLocationUri: new FormControl(''),
      referenceFileId: new FormControl(''),
    });
  }

  private async initializeFormOptions() {
    this.startSpinning();
    const {
      fileType, dataLocationUri, fileName, referenceFileId, genomeHashTableVersion, supportedGenomeIds, analysisVersionDefinitionId
    } = await this.data.pipe(first()).toPromise();
    const [ listGenomesResponse, referenceFileTypeOptions ] = await Promise.all([
      this.loadGenomes(genomeHashTableVersion, analysisVersionDefinitionId).toPromise(),
      this.getReferenceFileTypes().toPromise(),
    ]);
    this.genomesList = this.mapper.mapGenomeOptions(listGenomesResponse.items);
    this.referenceFileTypeOptions = referenceFileTypeOptions;
    this.referenceFileFormGroup.patchValue({ fileType, dataLocationUri, fileName, referenceFileId, supportedGenomeIds });
    this.stopSpinning();
  }

  private loadGenomes(hashTableVersion?: string, analysisVersionDefinitionId?: string) {
    const requestGenomeParam: GenomesService.ListGenomesParams = { pageSize: LIST_GENOMES_PAGE_SIZE };
    if (hashTableVersion) {
      requestGenomeParam.hashTableVersion = hashTableVersion;
    }
    if (analysisVersionDefinitionId) {
      requestGenomeParam.analysisVersionDefinitionId = analysisVersionDefinitionId;
    }
    return this.genomeService.listGenomes(requestGenomeParam).pipe(first());
  }

  /**
   * Get reference file types from GSS.
   * 1. Replace null with empty string
   * 2. Sort the list and put empty string on top
   * 3. Append "Other" to the list
   */
  private getReferenceFileTypes(): Observable<string[]> {
    return this.referenceFilesService.listReferenceFileTypes()
      .pipe(
        first(),
        map(resp => {
          const options = resp.referenceFileTypes;
          options.forEach((val, i) => {
            if (!val) {
              options[i] = '';
            }
          });
          options.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
          return options.concat(this.FILE_TYPE_OTHER);
        })
      );
  }

  accept(modal: NgxSmartModalComponent) {
    this.updateMetadata();
  }

  close(_modal: NgxSmartModalComponent) {
    this.closeModal.emit();
  }

  private updateMetadata() {
    this.startSpinning();
    this.postReferenceFile().subscribe({
      next: _file => {
        this.toastr.success(`Reference file details has been successfully added.`);
        /**
         * This is just to notify reference file dropdown that a new file has been added
         * The dropdown logic figure out themselves if that file should be added to the option.
         */
        this.confirm.emit(true);
      },
      error: err => {
        console.error(err);
        // The file would have been uploaded already
        this.toastr.error('Failed to add reference file details. You may edit the details at /resources/reference-files');
      },
      complete: () => {
        this.stopSpinning();
        this.modal.close();
      },
    });
  }

  private postReferenceFile(): Observable<ReferenceFile> {
    const updateReferenceFileRequest: UpdateReferenceFileRequest = {
      name: this.referenceFileFormGroup.value.fileName,
      description: this.referenceFileFormGroup.value.description,
      type: this.othersSelected ? this.referenceFileFormGroup.value.fileTypeOthers : this.referenceFileFormGroup.value.fileType,
      dataLocationUri: this.referenceFileFormGroup.value.dataLocationUri,
      supportedGenomeIds: this.referenceFileFormGroup.value.supportedGenomeIds,
    };

    return this.referenceFilesService.updateReferenceFile({
      referenceFileId: this.referenceFileFormGroup.value.referenceFileId,
      body: updateReferenceFileRequest,
    });
  }

  private startSpinning() {
    this.isUploading = true;
    this.refFileLoadingSpinner.start();
  }

  private stopSpinning() {
    this.isUploading = false;
    this.refFileLoadingSpinner.complete();
  }

  toggleOthers(event) {
    this.othersSelected = event && event.option
      && (event.option.value === this.FILE_TYPE_OTHER);
  }
}
